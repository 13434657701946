<template>
  <div class="w-100 fill-height">
    <v-container>
      <!-- Alerts -->
      <v-alert
        v-if="success"
        v-text="success"
        type="success"
        dense
        dismissible
        class="mb-3"
      />

      <v-alert
        v-if="error"
        v-text="error"
        type="error"
        dense
        dismissible
        class="mb-3"
      />

      <!-- Form -->
      <v-form ref="form">
        <v-card
          :loading="loading"
          outlined
          flat
        >
          <v-toolbar dense flat>
            <v-toolbar-title v-text="title" />
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <p class="red--text text--darken-1">* required input</p>

            <pibot-eprs-incident-briefing />

            <pibot-eprs-assessment-briefing />

            <!-- Follow-up type select -->
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <pibot-form-field
                  :field="nextSelect"
                  @change="({ value }) => next = value"
                  outlined
                />
              </v-col>
            </v-row>

            <!-- Dynamic form -->
            <component v-if="component" v-bind:is="component" ref="dynamic_form" />

            <!-- No dynamic form available -->
            <v-alert
              v-if="!component"
              type="info"
              outlined
              text
              dense
              class="mt-5 mb-0"
              v-text="'No available options'"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-checkbox
              v-model="checkbox"
              class="mt-0 mr-5"
              disabled
              label="Generate report"
              hide-details
            />

            <pibot-pw-confirm
              ref="confirm"
              elevation="0"
              color="success darken-1"
              :loading="loading"
              :disabled="!component"
              @click="validate()"
              @confirmed="onSubmit()"
            >
              <template #default>Submit</template>
              <template #title>Authenticate</template>
            </pibot-pw-confirm>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'

export default {
  name: 'pibot-eprs-assessment-form',
  mixins: [eprs],
  components: {
    'pibot-form-field': () => import('@/components/form-components/Field'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm'),
    'pibot-eprs-incident-briefing': () => import('../form-components/IncidentBriefing'),
    'pibot-eprs-assessment-briefing': () => import('../form-components/AssessmentBriefing'),
    'pibot-eprs-dropped-object-form': () => import('../form-components/assessments/DroppedObjectForm'),
    'pibot-eprs-dent-form': () => import('../form-components/assessments/DentForm'),
    'pibot-eprs-fatigue-form': () => import('../form-components/assessments/FatigueForm')
  },
  data: () => ({
    next: '',
    checkbox: true
  }),
  computed: {
    ...mapState({
      reportMeta: state => state.eprs.reportMeta
    }),
    title () {
      let title = 'Assessment'

      if (this.isNew) title = 'New assessment'
      else if (this.isRevision) title = 'Edit assessment'
      else if (this.isNext) title = 'Follow-up assessment'

      return title
    },
    nextSelect () {
      const options = [
        {
          text: 'Dropped Object',
          value: 'dropped-object'
        },
        {
          text: 'Dent-Gouge Combination',
          value: 'dent'
        },
        {
          text: 'Fatigue Life After Dent-Gouge Combination',
          value: 'fatigue'
        }
      ]

      return {
        type: 'select',
        model: 'next',
        value: this.next,
        label: 'Assessment type *',
        required: true,
        options
      }
    },
    component () {
      let component = null
      if (this.next) component = `pibot-eprs-${this.next}-form`
      return component
    }
  },
  async beforeMount () {
    this.incident = await this.getIncidentById(this.iid)
    if (!this.isNew) {
      this.assessment = this.incident.assessments.find(a => a.assessment_id === this.aid)
      this.next = this.assessment.assessment_type.replace(/\s/g, '-')
    }
  },
  methods: {
    ...mapActions({
      create: 'eprs/socket_createAssessment',
      update: 'eprs/socket_updateAssessment',
      generate: 'eprs/socket_generateReport',
      delete: 'eprs/socket_deleteReport'
    }),
    validate () {
      const isValid = this.$refs.dynamic_form.validate()

      // Easy fix for 'preventing' dialog if form is invalid
      if (!isValid) setTimeout(() => { this.$refs.confirm.dialog = false }, 10)

      return isValid
    },
    async onSubmit () {
      if (!this.validate()) return

      const payload = {
        incident_id: this.iid,
        assessment_id: this.aid,
        assessment_type: this.next.replace(/-/g, ' '),
        user_inputs: { ...this.$refs.dynamic_form.form },
        return_values: 'nothing',
        save_to_db: true,
        update_geojson: true
      }

      // Hard-coded unit conversions
      if (payload.user_inputs['dent depth']) payload.user_inputs['dent depth'] = payload.user_inputs['dent depth'] / 1000
      if (payload.user_inputs['gouge depth']) payload.user_inputs['gouge depth'] = payload.user_inputs['gouge depth'] / 1000

      let aid
      if (this.isNew || this.isNext) aid = await this.create(payload)
      else if (this.isRevision) {
        aid = this.aid
        const report = this.reportMeta.find((d) => parseInt(d.assessment_id) === aid && d.incident_id === this.iid)
        if (report) await this.delete(report.timestamp)
        await this.update(payload)
      }

      // Generate new report
      if (aid !== undefined && aid !== '') {
        await this.generate({
          incident_id: this.iid,
          assessment_id: aid
        })
      }

      this.$router.push('/eprs/')
    }
  }
}
</script>
